<template>
  <v-main>

    <v-container
        id="view-invoices-container"
        fluid
        tag="section"
    >

      <base-material-card
          icon="mdi-text-box-outline"
          class="px-5 py-3"
      >

        <v-card-text v-if="errors.length">
          <v-alert color="error"
                   outlined
                   dark
                   :key="error.id"
                   show variant="danger"
                   v-for="error in errors">
            {{ error }}
          </v-alert>
        </v-card-text>

        <v-card-text v-if="invoices">

          <v-simple-table
              fixed-header
          >
            <template v-slot:default>
              <thead>
              <tr>
                <th class="invoice-num text-subtitle-2">Invoice #</th>
                <th class="bill-to text-subtitle-2">Bill To</th>
                <th class="invoice-amount text-subtitle-2 text-right">Amount</th>
                <th class="invoice-balance text-subtitle-2 text-right">Balance</th>
                <th class="invoice-date text-subtitle-2">Inv. Date</th>
                <th class="due-date text-subtitle-2">Due Date</th>
                <th class="invoice-status text-subtitle-2">Status</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="invoice in invoices"
                  :key="invoice.id"
              >
                <td>
                  <router-link :to="{ name: 'view_invoice', params: {id: invoice.id} }">
                    {{ invoice.id }}
                  </router-link>
                </td>
                <td>
                  {{ invoice.user.firstName }} {{ invoice.user.middleName }} {{ invoice.user.lastName }}
                </td>
                <td class="text-right">{{ invoice.total | formatCurrency }}</td>
                <td class="text-right">{{ invoice.balance | formatCurrency }}</td>
                <td>{{ invoice.invoiceDate | formatDate }}</td>
                <td>{{ invoice.dueDate | formatDate }}</td>
                <td>{{ invoiceStatusMap[invoice.status] }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

        </v-card-text>

      </base-material-card>


    </v-container>

  </v-main>
</template>

<script>
import api from '@/services/api'
import constants from '@/utils/constants'
import {mapGetters} from "vuex";

export default {
  name: 'CoreViewInvoicesView',

  created() {
    this.$gtag.event('view_invoices')

    this.userId = this.getUserId
    this.fetchInvoices()
  },

  computed: {
    ...mapGetters({
      getUserId: 'getUserId'
    })
  },

  methods: {
    fetchInvoices() {
      api
          .fetchInvoices(this.userId)
          .then(response => {
            this.invoices = response.data
          })
          .catch(err => {
            this.handleError(err)
          })
    },
    handleError(err) {
      let errorMessage = api.parseResponseError(err)
      this.errors.push(errorMessage)
    }
  },

  data() {
    return {
      errors: [],
      userId: null,
      user: {
        country: 'US',
        idType: {}
      },
      invoices: [],
      invoiceStatusMap: constants.getInvoiceStatusMap(),
      paymentMethods: constants.getPaymentMethods()
    }
  }
}
</script>

<style lang="sass">
th.invoice-amount, th.invoice-status, th.invoice-balance
  width: 5%

th.invoice-num, th.invoice-date, th.due-date, th.invoice-amount, th.invoice-status
  width: 12%

</style>